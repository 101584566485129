import React, { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

import {
	addWidgetsToUpdateWaitingList,
	createWidget,
	deleteWidget,
	emptyWidgetsToUpdateWaitingList,
	getScenario,
	setRefreshSceneMedia,
	setOriginScenarioAction,
	setSaveScenario,
	setSceneLoading,
	updateEditingSceneAction,
	updateEditingSceneAndSendToPlayer,
	updateIsTimeLineChangedAction,
	updateScenarioActiveLayoutAction,
	updateWidget,
} from '../../../actions/scenarioActions';
import { loadPlayerConfiguration } from '../../../actions/playerConfigurationActions';
import { updateScenarioAction, updateScenesAction } from '../../../actions/scenarioActions';
import { getMediaQueryDetails, loadMediasForPagination } from '../../../actions/publishActions';
import { mediaNames, messageTypes } from '../../../constants/mediaConstants';
import { showMessage } from '../../../actions/globalActions';
import { generateUUID } from '../../../utils/commonUtil';
import {
	convertTimeToWidgetTime,
	getTimelineTimeFromTimeInput,
	getTimelineTimeFromSeconds,
	getSecondsFromTimelineTime,
} from '../../../services/timeStampService';
import ScenarioScreenContainer from '../utils/ScenarioScreenContainer';
import {
	getDefaultWidgetData,
	getVideoMediaDurationBySecond,
	delayAfterSeconds,
	validWidgetDuration,
} from '../utils/helper';
import { qplayerDomain } from '../../../utils/config';

import ScenarioEditorPlayerIframe from '../utils/ScenarioEditorPlayerIframe';
import WidgetOverlay from './widgetOverlay/WidgetOverlay';
import IconLibrary from '../../../reusable/IconLibrary/IconLibrary';
import {
	WIDGET_SHORT_NAME,
	WIDGET_TYPE_BUTTON,
	WIDGET_TYPE_ICON,
	WIDGET_TYPE_IMAGE,
	WIDGET_TYPE_VIDEO,
} from '../../../constants/scenarioConstant';
import InteractMediaLibraryModal from '../../../reusable/MediaLibrary/InteractMediaLibraryModal';
import EditorTimeline from '../EditorTimeline/EditorTimeline';
import SceneEditorSidebar from './sidebar/SceneEditorSidebar';
import AddWidgetModal from './addWidgetOnModal/AddWidgetModal';

import './SceneEditor.scss';

const baseClassName = 'scenario-editor';

class ScenarioEditor extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedTab: 'widgets',
			breadcrumbLevel: 'alllayers',
			editingBox: '1',
			editingWidget: null,
			mediaName: 'medias',
			open: true,
			activeLayout: this.props.activeLayout ?? 'desktop',
			selectedWidgetType: undefined,
			showSelectWidgetTypeModal: false,
			showPlayer: true,
			sceneHasVideo: false,
			selectedPlayerId: '',
			showIconLibraryModal: false,
			playerAreaHeight: '',
			playerAreaWidth: '',
			shouldReloadPlayer: Math.random(),
		};

		this.tempWidgetData = {};
		this.pauseHandler = null;
		this.playHandler = null;
		this.forwardHandler = null;
		this.rewardHandler = null;
		this.muteHandler = null;
		this.unMuteHandler = null;
		this.movePositionHandler = null;
		this.addHoverImageRef = createRef();
		this.interactMediaLibraryModalRef = createRef();
		this.scenarioContainerRef = createRef();
		this.widgetOverlayRef = createRef();
		this.debounceTimer = createRef();
		this.timelineEditorRef = createRef();
		this.timelineContainerRef = createRef();
		this.mainContainer = createRef();
		this.widgetSettingRef = createRef();
		this.sceneBGMedia = '';
	}

	componentDidMount() {
		this.loadScenario(() => this.setState({ showPlayer: true }));
		this.loadPlayerConfiguration();

		window.addEventListener('resize', this.getPlayerAreaHeight);
		this.getPlayerAreaHeight();

		//Config Zen desk widget
		const temp = setInterval(() => {
			if (window.zE.show) {
				window.zE('webWidget', 'updateSettings', {
					webWidget: {
						position: { horizontal: 'left' },
					},
				});
				window.zE.show && window.zE.show();
				clearInterval(temp);
			}
		}, 1000);
	}

	componentWillUnmount() {
		window.zE.hide && window.zE.hide();
		window.zE('webWidget', 'updateSettings', {
			webWidget: {
				position: { horizontal: 'right' },
			},
		});
		window.removeEventListener('resize', this.getPlayerAreaHeight);
	}

	componentDidUpdate(prevProps) {
		const {
			scenes,
			editingScene,
			updateEditingSceneAndSendToPlayer,
			match,
			updateWidgetWaitingDictionary,
			updateWidget,
			emptyWidgetsToUpdateWaitingList,
			activeLayout,
			sceneMediaIdsToRefresh,
		} = this.props;
		const { sceneHasVideo, selectedWidgetType } = this.state;

		if (editingScene && match.params.sceneId && match.params.sceneId !== editingScene?.id) {
			const newEditingScene = scenes.find((scene) => scene?.id === match.params.sceneId);
			newEditingScene && updateEditingSceneAndSendToPlayer(newEditingScene);
			this.setState({ editingWidget: null, breadcrumbLevel: 'alllayers' });
			return;
		}

		if (prevProps.editingScene !== editingScene) {
			const bgBoxWidgets =
				this.getCurrentLayout(editingScene).boxes?.find((b) => b.boxTemplateId === '1')?.widgets ?? [];
			let newSceneHasVideo = false;
			bgBoxWidgets.forEach((bgWidget) => {
				if (
					editingScene.widgetTemplates.find(
						(tmp) => tmp.id === bgWidget.widgetTemplateId && tmp.type === 'video'
					)
				) {
					newSceneHasVideo = true;
					return;
				}
			});
			if (newSceneHasVideo !== sceneHasVideo) {
				this.setState({
					selectedWidgetType: newSceneHasVideo ? undefined : selectedWidgetType,
					sceneHasVideo: newSceneHasVideo,
				});
			}
		}

		// check if there are anything need to save to api
		if (Object.keys(updateWidgetWaitingDictionary).length !== 0) {
			this.debouncedSaving(() => {
				Object.values(updateWidgetWaitingDictionary).forEach((value) => {
					const { defaultAccountId, scenarioId, sceneId, layoutId, boxId, widgetId, body, widgetType } =
						value;

					this.scenarioContainerRef.current?.isAutoSaving(true);

					// To quietly remove unused data of widgets
					// This code block should be removed after some time
					if (widgetType !== 'text' && body?.style?.transformSettings) {
						const transformSettings = body?.style?.transformSettings ?? {};
						let newTransformSettings = {};
						Object.keys(transformSettings).map((key) => {
							if (
								![
									'radiusTop',
									'radiusBottom',
									'radiusLeft',
									'radiusRight',
									'hoverRadiusBottom',
									'hoverRadiusLeft',
									'hoverRadiusTop',
									'hoverRadiusRight',
								].includes(key)
							) {
								newTransformSettings = {
									...newTransformSettings,
									[key]: transformSettings[key],
								};
							}
						});
						body.style.transformSettings = newTransformSettings;
					}

					updateWidget(defaultAccountId, scenarioId, sceneId, layoutId, boxId, widgetId, body).then(
						(resolve) => {
							if (resolve) {
								this.scenarioContainerRef.current?.isAutoSaving(false);
							}
						}
					);
				});
				emptyWidgetsToUpdateWaitingList();
			}, 2000);
		}

		// check if active layout is changed
		if (activeLayout !== prevProps.activeLayout) {
			this.getPlayerAreaHeight();
		}

		if (
			sceneMediaIdsToRefresh !== prevProps.sceneMediaIdsToRefresh &&
			sceneMediaIdsToRefresh.includes(this.sceneBGMedia)
		) {
			const newFinishedJobs = sceneMediaIdsToRefresh.filter((s) => s !== this.sceneBGMedia);
			this.props.setRefreshSceneMedia(newFinishedJobs);
			editingScene && updateEditingSceneAndSendToPlayer(editingScene);
			this.setState({ shouldReloadPlayer: Math.random() }, () => {});
		}
	}

	getPlayerAreaHeight = () => {
		requestAnimationFrame(() => {
			if (!this.timelineContainerRef?.current || !this.mainContainer?.current) {
				return;
			}
			const mainContainerHeight = this.mainContainer.current?.getBoundingClientRect().height ?? 0;
			const timelineHeight = this.timelineContainerRef?.current?.getBoundingClientRect().height ?? 300;
			let newHeight = mainContainerHeight - timelineHeight - 48;
			let newWidth = (newHeight / 9) * 16;

			if (this.state.activeLayout === 'mobile') {
				newHeight = mainContainerHeight - timelineHeight - 24;
				newWidth = (newHeight / 16) * 9;
			}

			this.setState((prevState) => {
				if (prevState.playerAreaHeight === newHeight) {
					return null;
				}
				return {
					...prevState,
					playerAreaHeight: newHeight,
					playerAreaWidth: newWidth,
				};
			});
		});
	};

	loadScenario = (callback) => {
		if (this.props.scenario?.id) {
			const { editingScene, updateEditingSceneAndSendToPlayer } = this.props;
			const { sceneHasVideo } = this.state;
			const bgBoxWidgets =
				this.getCurrentLayout(editingScene).boxes?.find((b) => b.boxTemplateId === '1')?.widgets ?? [];
			let newSceneHasVideo = false;
			bgBoxWidgets.forEach((bgWidget) => {
				const bgTemplate = editingScene.widgetTemplates.find(
					(tmp) => tmp.id === bgWidget.widgetTemplateId && tmp.type === 'video'
				);
				if (bgTemplate) {
					this.sceneBGMedia = bgTemplate.settings.mediaId;
					newSceneHasVideo = true;
					return;
				}
			});

			updateEditingSceneAndSendToPlayer(editingScene);

			if (newSceneHasVideo !== sceneHasVideo) {
				this.setState(
					{
						selectedWidgetType: newSceneHasVideo ? undefined : selectedWidgetType,
						sceneHasVideo: newSceneHasVideo,
					},
					() => {
						setTimeout(() => {
							this.props.setSceneLoading(false);
							callback?.();
						}, 1000);
					}
				);
			} else {
				setTimeout(() => {
					this.props.setSceneLoading(false);
					callback?.();
				}, 1000);
			}

			return;
		}

		const scenarioId =
			this.props.match && this.props.match.params && this.props.match.params.scenarioId
				? this.props.match.params.scenarioId
				: this.props.scenario?.id;
		const sceneId =
			this.props.match && this.props.match.params && this.props.match.params.sceneId
				? this.props.match.params.sceneId
				: this.props.scenario?.defaults?.sceneId;

		setTimeout(() => {
			const {
				setOriginScenarioAction,
				setSceneLoading,
				getScenario,
				defaultAccountId,
				updateScenarioActiveLayoutAction,
				updateScenarioAction,
				updateScenesAction,
				updateEditingSceneAndSendToPlayer,
				history,
				t,
			} = this.props;

			if (!scenarioId) {
				setSceneLoading(false);
				callback?.();
				return;
			}

			getScenario(defaultAccountId, scenarioId).then((updatedScenario) => {
				if (!updatedScenario) {
					setSceneLoading(false);
					callback?.();
					showMessage(t('SCENARIO_SCENE_EDITOR_CANNOT_GET_SCENARIO'), messageTypes.error);
					return;
				}

				const activeLayout = updatedScenario?.defaults?.type ?? 'desktop';
				const editingScene = (updatedScenario.scenes ?? []).find((s) => s.id === sceneId);

				if (!editingScene) {
					history.push({ pathname: `/medialibrary/interact/${scenarioId}/chart`, state: { from: 'editor' } });
					return;
				}

				const bgBoxWidgets =
					this.getCurrentLayout(editingScene).boxes?.find((b) => b.boxTemplateId === '1')?.widgets ?? [];

				let newSceneHasVideo = false;
				bgBoxWidgets.forEach((bgWidget) => {
					const bgTemplate = editingScene.widgetTemplates.find(
						(tmp) => tmp.id === bgWidget.widgetTemplateId && tmp.type === 'video'
					);
					if (bgTemplate) {
						this.sceneBGMedia = bgTemplate.settings.mediaId;
						newSceneHasVideo = true;
						return;
					}
				});
				updateScenarioActiveLayoutAction(activeLayout);
				updateScenarioAction(updatedScenario);
				updateScenesAction(updatedScenario.scenes ?? []);
				updateEditingSceneAndSendToPlayer(editingScene);
				updateIsTimeLineChangedAction(true);
				setSceneLoading(false);
				setOriginScenarioAction(updatedScenario);
				this.setState(
					{
						activeLayout: activeLayout,
						sceneHasVideo: newSceneHasVideo,
						selectedWidgetType: newSceneHasVideo ? undefined : 'video',
					},
					() => callback?.()
				);
			});
		}, 1000);
	};

	updateWidgetSettings = () => {
		const { editingWidget } = this.state;
		const { editingScene, updateEditingSceneAndSendToPlayer, uploadedMediaId } = this.props;

		const widgetTemplates = (editingScene.widgetTemplates ?? []).filter(
			(widget) => widget.id !== editingWidget?.widgetTemplateId
		);
		const currentTemplateWidget = (editingScene.widgetTemplates ?? []).find(
			(widget) => widget.id === editingWidget?.widgetTemplateId
		);
		if (!currentTemplateWidget) {
			return;
		}

		const mediaId = uploadedMediaId.split('.')[0];
		currentTemplateWidget.settings = { mediaId: mediaId };
		updateEditingSceneAndSendToPlayer(
			{ ...editingScene, widgetTemplates: [...widgetTemplates, currentTemplateWidget] },
			true
		);
	};

	handleDragStart = (event) => {
		const widgetType = event.target?.getAttribute('data-type');
		const tempWidgetData = {
			id: generateUUID(),
			type: widgetType,
			content: `New ${widgetType} widget`,
		};
		event.dataTransfer.setData('text', JSON.stringify(tempWidgetData));
		this.setState({ selectedWidgetType: widgetType, editingWidget: null });
	};

	onDropWidgetOnTimeline = (dropData) => {
		const { event } = dropData;
		const widgetDataFromDragEvent = JSON.parse(event.dataTransfer.getData('text') || {});
		const { type } = widgetDataFromDragEvent;

		this.tempWidgetData = {
			...widgetDataFromDragEvent,
			...dropData,
			group: '1',
		};

		if (type === 'image') {
			this.setState({ selectedWidgetType: type }, () => {
				this.interactMediaLibraryModalRef.current.showMediaLibrary(true);
			});
		} else if (type === 'icon') {
			this.setState({ showIconLibraryModal: true, selectedWidgetType: type });
		} else {
			this.addWidget({ group: '1', coordinate: { x: 0, y: 0 } });
		}
	};

	onDropWidgetOnVideoEditor = (event, svgOverlay) => {
		this.pauseHandler?.();
		let widgetDataFromDragEvent = {};
		try {
			widgetDataFromDragEvent = JSON.parse(event.dataTransfer.getData('text') || {});
		} catch (e) {
			return;
		}
		const { type } = widgetDataFromDragEvent;

		const { clientX, clientY } = event;
		let pt = svgOverlay.createSVGPoint();
		pt.x = clientX;
		pt.y = clientY;
		const { x, y } = pt.matrixTransform(svgOverlay.getScreenCTM().inverse());

		this.tempWidgetData = {
			...widgetDataFromDragEvent,
			group: '1',
			time: getTimelineTimeFromTimeInput(),
			coordinate: { x, y },
		};

		if (type === 'image') {
			this.setState({ selectedWidgetType: type }, () => {
				this.interactMediaLibraryModalRef.current.showMediaLibrary(true);
			});
		} else if (type === 'icon') {
			this.setState({ showIconLibraryModal: true, selectedWidgetType: type });
		} else {
			this.addWidget({ group: '1', coordinate: { x, y } });
		}
	};

	onUploadMediaClick = (type) => {
		this.setState({ selectedWidgetType: type }, () => {
			this.interactMediaLibraryModalRef.current.showMediaLibrary(true);
		});
	};

	onTabItemClick = (_event, item) => {
		if (item.id === 'edit') {
			this.setState({ breadcrumbLevel: 'alllayers' });
		}
		this.setState({ selectedTab: item.id });
	};

	setBreadcrumbLevel = (event, breadcrumbLevel) => {
		event.preventDefault();
		this.setState({ breadcrumbLevel });
	};

	onWidgetClick = (event, widget) => {
		event.preventDefault();
		this.setState({ breadcrumbLevel: 'widget', editingWidget: widget });
	};

	onSelectWidgetType = (widgetType) => {
		this.tempWidgetData.type = widgetType;
		this.setState({ selectedWidgetType: widgetType });
	};

	onTimelineItemClick = (item) => {
		if (item?.group && item?.group !== 'video-group') {
			// Move timeline and video to start position of widget
			const { editingScene } = this.props;
			const currentLayout = this.getCurrentLayout(editingScene);
			const currentBox = (currentLayout.boxes ?? []).find((box) => box.boxTemplateId === item.boxId);
			const editingWidget = (currentBox?.widgets ?? []).find((w) => w.id === item.id);
			const widgetStartPosition = getSecondsFromTimelineTime(getTimelineTimeFromTimeInput(editingWidget.start));
			const currentPositionInSecond = getSecondsFromTimelineTime(this.currentMediaPosition);

			const isDisplayingWidget =
				currentPositionInSecond >= widgetStartPosition &&
				currentPositionInSecond <= getSecondsFromTimelineTime(getTimelineTimeFromTimeInput(editingWidget.end));

			if (!isDisplayingWidget) {
				this.movePositionHandler?.(widgetStartPosition);
			}
		}
		this.pauseHandler?.();
		this.setState({ editingWidget: item, editingBox: item.boxId, breadcrumbLevel: 'widget', selectedTab: 'edit' });
	};

	onAddWidgetClick = () => {
		this.setState({ showSelectWidgetTypeModal: true });
	};

	onAddWidgetPromptAccept = () => {
		if (this.state.selectedWidgetType === 'video') {
			if (this.state.sceneHasVideo) {
				return;
			}
			this.setState({ mediaType: type, showSelectWidgetTypeModal: false }, () => {
				this.interactMediaLibraryModalRef.current.showMediaLibrary(true);
			});

			return;
		}
		this.addWidget();
	};

	removeWidgetInBox = (widget) => {
		const { editingBox, activeLayout } = this.state;
		const { editingScene, updateEditingSceneAction, defaultAccountId, scenario, deleteWidget } = this.props;

		this.setState({ selectedTab: 'edit', breadcrumbLevel: 'alllayers' });

		let currentLayout = this.getCurrentLayout(editingScene);
		const layouts = (editingScene.layouts ?? []).filter((l) => l.type !== activeLayout);

		const currentBox = (currentLayout.boxes ?? []).find((box) => box.boxTemplateId === editingBox);
		const boxes = (currentLayout.boxes ?? []).filter((box) => box.boxTemplateId !== editingBox);

		const boxWidgets = (currentBox.widgets ?? []).filter((w) => w.id !== widget.id);
		currentBox.widgets = [...boxWidgets];
		currentLayout.boxes = [...boxes, currentBox];

		const widgetTemplates = editingScene.widgetTemplates.filter(({ id }) => id !== widget.widgetTemplateId);

		//ui update
		this.setState({
			editingWidget: null,
		});
		updateEditingSceneAction({ ...editingScene, layouts: [...layouts, currentLayout], widgetTemplates });

		//saving to api
		const { id: scenarioId } = scenario;
		const { id: sceneId } = editingScene;
		const { id: layoutId } = currentLayout;
		this.scenarioContainerRef.current.isAutoSaving(true);
		deleteWidget(defaultAccountId, scenarioId, sceneId, layoutId, '1', widget.id).then((resolve) => {
			if (resolve) {
				this.scenarioContainerRef.current.isAutoSaving(false);
			}
		});
	};

	duplicateWidget = (widget, templateToCopy) => {
		const { editingBox } = this.state;
		const {
			editingScene,
			updateEditingSceneAndSendToPlayer,
			scenario,
			defaultAccountId,
			createWidget,
			editingSceneDuration,
		} = this.props;

		const newWidgetId = generateUUID();
		const newWidgetTemplateId = generateUUID();

		const oldWidgetTemplate =
			templateToCopy ?? editingScene.widgetTemplates.find((template) => template.id === widget.widgetTemplateId);

		const clonedTemplate = cloneDeep(oldWidgetTemplate);
		const newWidgetTemplate = {
			...clonedTemplate,
			id: newWidgetTemplateId,
			name: `${clonedTemplate.name} (Copy)`,
			style: {
				...clonedTemplate.style,
				zIndex: this.timelineEditorRef.current?.getHighestZIndexValue() + 1,
			},
		};

		const currentLayout = this.getCurrentLayout(editingScene);
		const layouts = (editingScene?.layouts ?? []).filter((l) => l.id !== currentLayout.id);

		const currentBoxId = editingBox;
		const currentBox = (currentLayout?.boxes ?? []).find((box) => box.boxTemplateId === currentBoxId) ?? {};
		const boxes = (currentLayout?.boxes ?? []).filter((box) => box.boxTemplateId !== currentBoxId);
		let boxWidgets = (currentBox?.widgets ?? []).sort((a, b) => {
			const aStart = typeof a.start === 'string' ? new Date(a.start) : a.start;
			const bStart = typeof b.start === 'string' ? new Date(b.start) : b.start;
			return aStart - bStart;
		});

		let newWidgetInBox = boxWidgets.find((w) => w.id === widget.id);

		newWidgetInBox = newWidgetInBox
			? {
					...newWidgetInBox,
					id: newWidgetId,
					widgetTemplateId: newWidgetTemplateId,
			  }
			: {
					id: newWidgetId,
					widgetTemplateId: newWidgetTemplateId,
					start: '00:00:00.000',
					end: convertTimeToWidgetTime(moment(editingSceneDuration.end)) || '00:00:05.000',
			  };
		currentBox.widgets = [...boxWidgets, newWidgetInBox];
		currentLayout.boxes = [...boxes, currentBox];
		updateEditingSceneAndSendToPlayer(
			{
				...(editingScene ?? {}),
				widgetTemplates: [...(editingScene?.widgetTemplates ?? []), newWidgetTemplate],
				layouts: [...layouts, currentLayout],
			},
			true
		);

		//save to api
		const { id: scenarioId } = scenario;
		const { id: sceneId } = editingScene;
		const { id: layoutId } = currentLayout;
		const { name, type, settings, style, events } = newWidgetTemplate;
		const { start, end } = newWidgetInBox;

		const widgetBody = {
			name,
			widgetTemplateId: newWidgetTemplateId,
			start,
			end,
			type,
			settings,
			style,
			events,
		};
		this.scenarioContainerRef.current.isAutoSaving(true);
		createWidget(defaultAccountId, scenarioId, sceneId, layoutId, '1', newWidgetInBox.id, widgetBody).then(
			(resolve) => {
				if (resolve) {
					this.scenarioContainerRef.current.isAutoSaving(false);
				}
			}
		);
	};

	addWidget = (media) => {
		const { selectedWidgetType, editingBox, sceneHasVideo } = this.state;
		const {
			editingScene,
			setSceneLoading,
			editingSceneDuration,
			updateEditingSceneAction,
			createWidget,
			defaultAccountId,
			scenario,
		} = this.props;

		if (selectedWidgetType === WIDGET_TYPE_VIDEO && sceneHasVideo) {
			return;
		}

		const newWidgetTemplateId = generateUUID();
		const newBoxWidgetId = generateUUID();

		let settings = {};

		switch (selectedWidgetType) {
			case WIDGET_TYPE_VIDEO: {
				settings = { mediaId: media?.id };
				break;
			}
			case WIDGET_TYPE_IMAGE: {
				settings = { imgUrl: media?.imageUrl };
				break;
			}

			case WIDGET_TYPE_ICON: {
				settings = { iconKey: media?.iconKey };
				break;
			}

			case WIDGET_TYPE_BUTTON: {
				settings = { iconKey: undefined };
				break;
			}
		}

		let newWidgetTemplate = {
			...getDefaultWidgetData(
				newWidgetTemplateId,
				`${WIDGET_SHORT_NAME[selectedWidgetType]}#${
					media?.name?.substring(0, 20) ?? moment(new Date()).format('yyyy-MM-DD T HH:mm:ss')
				}`,
				selectedWidgetType,
				media?.coordinate,
				this.timelineEditorRef.current?.getHighestZIndexValue() + 1,
				media?.ratio
			),
			settings,
		};

		const endTime = media?.end || convertTimeToWidgetTime(moment(editingSceneDuration.end)) || '00:00:05.000';
		let newWidgetInBox = {
			id: newBoxWidgetId,
			widgetTemplateId: newWidgetTemplateId,
			start: '00:00:00.000',
			end: endTime,
		};

		const currentLayout = this.getCurrentLayout(editingScene);
		const layouts = (editingScene?.layouts ?? []).filter((l) => l.id !== currentLayout.id);

		const currentBoxId = media?.group ?? editingBox ?? this.tempWidgetData?.group;
		const currentBox = (currentLayout?.boxes ?? []).find((box) => box.boxTemplateId === currentBoxId) ?? {};
		const boxes = (currentLayout?.boxes ?? []).filter((box) => box.boxTemplateId !== currentBoxId);
		const boxWidgets = currentBox?.widgets ?? [];

		currentBox.widgets = [...boxWidgets, newWidgetInBox];
		currentLayout.boxes = [...boxes, currentBox];

		const newEditingScene = {
			...(editingScene ?? {}),
			widgetTemplates: [...(editingScene?.widgetTemplates ?? []), newWidgetTemplate],
			layouts: [...layouts, currentLayout],
		};
		// ui update
		updateEditingSceneAction(newEditingScene);
		this.setState({ showSelectWidgetTypeModal: false, selectedWidgetType: undefined }, () => {
			setSceneLoading(false);
			this.setState(() => ({
				editingBox: currentBoxId,
				editingWidget: { ...newWidgetInBox, boxId: currentBoxId },
				selectedTab: 'edit',
				breadcrumbLevel: 'widget',
			}));

			//save to api
			const { id: scenarioId } = scenario;
			const { id: sceneId } = editingScene;
			const { id: layoutId } = currentLayout;
			const { name, type, settings, style, events } = newWidgetTemplate;
			const { start, end } = newWidgetInBox;

			const widgetBody = {
				name,
				widgetTemplateId: newWidgetTemplateId,
				start,
				end,
				type,
				settings,
				style,
				events,
			};
			this.scenarioContainerRef.current.isAutoSaving(true);
			createWidget(defaultAccountId, scenarioId, sceneId, layoutId, '1', newBoxWidgetId, widgetBody).then(
				(resolve) => {
					if (resolve) {
						this.scenarioContainerRef.current.isAutoSaving(false);
					}
				}
			);
		});
	};

	addMediaToWidget = (selectedMedias) => {
		const {
			t,
			getMediaQueryDetails,
			defaultAccountId,
			showMessage,
			setSceneLoading,
			editingScene,
			editingSceneDuration,
		} = this.props;
		const { editingWidget } = this.state;
		const selectedMedia = selectedMedias[0];
		const editingWidgetTemplate = ((editingScene ?? {}).widgetTemplates ?? []).find(
			(widget) => widget.id === editingWidget?.widgetTemplateId
		);
		const isReplaceImageWidget = editingWidgetTemplate && editingWidgetTemplate.type === 'image';
		const isChangingMediaFromVideoWidget = editingWidgetTemplate && editingWidgetTemplate.type === 'video';

		setSceneLoading(true);
		if (selectedMedia.type === 'uploadNew') {
			delayAfterSeconds(5)
				.then(() => getMediaQueryDetails(defaultAccountId, selectedMedia.id, mediaNames.medias))
				.then((media) => getVideoMediaDurationBySecond(media, selectedMedia.file))
				.then((media) =>
					this.processMediaAfterLoaded(
						media,
						this.tempWidgetData,
						isReplaceImageWidget,
						isChangingMediaFromVideoWidget,
						editingSceneDuration,
						editingWidgetTemplate
					)
				)
				.catch((error) => {
					if (error === 404) {
						showMessage(`${t('MEDIA_LIBRARY_NOTIFICATION_REFRESH_TO_REVIEW_CONTENT')}`, messageTypes.info);
					}
					setSceneLoading(false);
				});
		} else {
			this.processMediaAfterLoaded(
				selectedMedia,
				this.tempWidgetData,
				isReplaceImageWidget,
				isChangingMediaFromVideoWidget,
				editingSceneDuration,
				editingWidgetTemplate
			);
		}
	};

	processMediaAfterLoaded = (
		media,
		tempWidgetData,
		isReplaceImageWidget,
		isChangingMediaFromVideoWidget,
		editingSceneDuration,
		editingWidgetTemplate
	) => {
		const { showMessage, setSceneLoading, t } = this.props;
		let imageUrl;
		let ratio;
		const type = tempWidgetData?.type ?? editingWidgetTemplate?.type;

		if (type === 'image') {
			const imageRendition = media.asset?.resources?.find((r) => r.type === 'image')?.renditions?.[0];
			imageUrl = media.download ?? imageRendition?.links?.[0]?.href ?? undefined;
			ratio =
				(imageRendition?.height ? imageRendition?.height : 100) /
				(imageRendition?.width ? imageRendition?.width : 150);
		}

		if (isReplaceImageWidget) {
			imageUrl && this.updateImageToImageWidget(imageUrl, editingWidgetTemplate);

			if (!imageUrl) {
				showMessage(t('SCENARIO_SCENE_EDITOR_IMAGE_UPLOADED'), messageTypes.info);
				setSceneLoading(false);
			}
			return;
		}

		if (isChangingMediaFromVideoWidget) {
			this.updateMediaToVideoWidget(media, editingWidgetTemplate);
			return;
		}

		this.addWidget({
			group: '1',
			name: media.metadata?.title ?? `New ${type} widget`,
			id: media.id,
			start: '00:00:00.000',
			end: convertTimeToWidgetTime(moment(editingSceneDuration.end)) || '00:00:05.000',
			imageUrl,
			coordinate: tempWidgetData.coordinate,
			ratio,
		});
	};

	updateImageToImageWidget = (imageUrl, widgetTemplate) => {
		const isHoverImageTabOpen = this.addHoverImageRef.current;
		const { setSceneLoading, updateEditingSceneAndSendToPlayer, editingScene } = this.props;
		const { editingWidget, editingBox } = this.state;
		const restWidgetTemplate = (editingScene.widgetTemplates ?? []).filter(
			(template) => template.id !== widgetTemplate.id
		);

		const widgetUpdateBody = {
			settings: { ...widgetTemplate.settings, [isHoverImageTabOpen ? 'bgImgUrl' : 'imgUrl']: imageUrl },
		};
		const newWidgetTemplate = {
			...widgetTemplate,
			...widgetUpdateBody,
		};
		const newEditingScene = { ...editingScene, widgetTemplates: [...restWidgetTemplate, newWidgetTemplate] };

		const currentLayout = this.getCurrentLayout(editingScene);
		const currentBox = (currentLayout.boxes ?? []).find((box) => box.boxTemplateId === editingBox);
		const currentWidget = (currentBox.widgets ?? []).filter((w) => w.id === editingWidget.id)?.[0] ?? {};

		updateEditingSceneAndSendToPlayer(newEditingScene);
		setSceneLoading(false);
		this.initUpdateWidgetToApi(newEditingScene, currentBox, currentWidget, widgetUpdateBody);
	};

	updateMediaToVideoWidget = (media, widgetTemplate, editingWidgetFromTimeline) => {
		const { setSceneLoading, updateEditingSceneAndSendToPlayer, editingScene } = this.props;
		const { editingWidget: stateEditingWidget, editingBox } = this.state;
		let editingWidget = editingWidgetFromTimeline ?? stateEditingWidget;

		const duration =
			media?.duration ||
			media?.asset?.resources?.find((r) => r.type === 'video')?.renditions?.[0]?.videos?.[0]?.duration ||
			this.getSceneDuration() / 1000 ||
			5;

		const updateWidgetTemplate = {
			name: media?.metadata?.title ? `${media?.metadata?.title} widget` : `New video widget`,
			settings: {
				mediaId: media?.id,
			},
		};

		const newWidgetTemplate = {
			...widgetTemplate,
			...updateWidgetTemplate,
		};

		const restWidgetTemplate = (editingScene.widgetTemplates ?? []).filter(
			(template) => template?.id !== widgetTemplate?.id
		);

		const currentLayout = this.getCurrentLayout(editingScene);
		const layouts = (editingScene?.layouts ?? []).filter((l) => l.id !== currentLayout.id);

		const currentBox = (currentLayout.boxes ?? []).find((box) => box.boxTemplateId === editingBox);
		const boxes = (currentLayout.boxes ?? []).filter((box) => box.boxTemplateId !== editingBox);

		const boxWidgets = (currentBox.widgets ?? []).filter((w) => w.id !== editingWidget?.id);
		const currentWidget = (currentBox.widgets ?? []).filter((w) => w.id === editingWidget?.id)?.[0] ?? {};

		const widgetEndTime =
			duration === 0 ? '00:00:00' : convertTimeToWidgetTime(getTimelineTimeFromSeconds(duration));

		const newWidgetInBox = {
			...currentWidget,
			sceneId: editingScene.id,
			start: '00:00:00',
			end: widgetEndTime,
		};

		const modifiedWidgets = [...validWidgetDuration(boxWidgets, duration), newWidgetInBox];
		currentBox.widgets = modifiedWidgets;
		currentLayout.boxes = [...boxes, currentBox];

		const newEditingScene = {
			...(editingScene ?? {}),
			widgetTemplates: [...restWidgetTemplate, newWidgetTemplate],
			layouts: [...layouts, currentLayout],
		};

		const widgetBodyToApi = {
			...updateWidgetTemplate,
			type: WIDGET_TYPE_VIDEO,
			start: '00:00:00',
			end: widgetEndTime,
		};

		updateEditingSceneAndSendToPlayer(newEditingScene);
		setSceneLoading(false);
		this.initUpdateWidgetToApi(newEditingScene, currentBox, currentWidget, widgetBodyToApi);
	};

	sendMessToIframe = (data) => {
		const playerIframe = document.getElementById('temp-player');
		if (playerIframe) {
			playerIframe.contentWindow.postMessage(data, '*');
		}
	};

	onExitButtonClick = () => {
		const { updateScenesAction, updateScenarioAction, scenes, scenario, editingScene } = this.props;
		const remainScenes = scenes.filter((s) => s?.id !== editingScene?.id);

		updateScenesAction([...remainScenes, editingScene]);
		updateScenarioAction({ ...scenario, scenes: [...remainScenes, editingScene] });

		this.props.history.push({
			pathname: `/medialibrary/interact/${scenario.id}/chart`,
			state: { from: 'editor', lastEditedSceneId: editingScene.id },
		});
	};

	loadPlayerConfiguration = () => {
		const { loadPlayerConfiguration, defaultAccountId, showMessage, t } = this.props;

		loadPlayerConfiguration(defaultAccountId).then((data) => {
			if (!data) {
				showMessage(t('SETTINGS_PRESET_CONFIG_LOAD_DATA_FAIL'), messageTypes.error);
				return;
			}

			let selectedPlayerConfig = data.find((config) => config.recommended);
			selectedPlayerConfig = selectedPlayerConfig ?? data[0];
			this.setState({ selectedPlayerId: selectedPlayerConfig.id });
		});
	};

	onPreviewButtonClick = () => {
		const { selectedPlayerId } = this.state;
		const { defaultAccountId, scenario, editingScene } = this.props;
		const previewURL = `${qplayerDomain}?accountId=${defaultAccountId}&scenarioId=${scenario?.id}${
			editingScene?.id ? `&sceneId=` + editingScene.id : ''
		}&configId=${selectedPlayerId}`;
		window.open(previewURL, '_blank');
	};

	// icon widget funcs
	addSelectedIconToIconWidget = (iconKey) => {
		const { editingSceneDuration, setSceneLoading, updateEditingSceneAndSendToPlayer, editingScene } = this.props;
		const { editingWidget, editingBox } = this.state;

		this.setState({ showIconLibraryModal: false });
		if (!editingWidget) {
			this.addWidget({
				group: '1',
				name: iconKey,
				id: generateUUID(),
				start: '00:00:00.000',
				end: convertTimeToWidgetTime(moment(editingSceneDuration.end)) || '00:00:05.000',
				iconKey,
				coordinate: this.tempWidgetData.coordinate,
			});
		} else {
			const editingWidgetTemplate = ((editingScene ?? {}).widgetTemplates ?? []).find(
				(widget) => widget.id === editingWidget?.widgetTemplateId
			);

			const restWidgetTemplate = (editingScene.widgetTemplates ?? []).filter(
				(template) => template.id !== editingWidgetTemplate.id
			);

			const widgetBodyToApi = {
				settings: {
					...editingWidgetTemplate.settings,
					iconKey,
				},
			};
			const newWidgetTemplate = {
				...editingWidgetTemplate,
				...widgetBodyToApi,
			};
			const newEditingScene = { ...editingScene, widgetTemplates: [...restWidgetTemplate, newWidgetTemplate] };

			const currentLayout = this.getCurrentLayout(editingScene);
			const currentBox = (currentLayout.boxes ?? []).find((box) => box.boxTemplateId === editingBox);
			const currentWidget = (currentBox.widgets ?? []).filter((w) => w.id === editingWidget.id)?.[0] ?? {};

			updateEditingSceneAndSendToPlayer(newEditingScene);
			this.initUpdateWidgetToApi(newEditingScene, currentBox, currentWidget, widgetBodyToApi);
			setSceneLoading(false);
		}
	};

	onShowIconLibraryPickerModal = () => {
		this.setState({ showIconLibraryModal: true });
	};

	getCurrentLayout = (editingScene) => {
		// for 1st version, only using desktop layout for both type
		let currentLayout = (editingScene?.layouts ?? []).find((l) => l.type === 'desktop') ?? {};

		if (Object.keys(currentLayout).length === 0) {
			// fix for case old interact only have mobile layout
			currentLayout = editingScene?.layouts?.[0] ?? {};
			currentLayout.type = 'desktop';
		}

		return currentLayout;
	};

	debouncedSaving = (func, timeout = 300) => {
		clearTimeout(this.debounceTimer.current);
		this.debounceTimer.current = setTimeout(func, timeout);
	};

	initUpdateWidgetToApi = (newEditingScene, editingBox, editingWidget, updateBody) => {
		const { scenario, defaultAccountId, addWidgetsToUpdateWaitingList } = this.props;
		const { id: scenarioId } = scenario;

		if (!newEditingScene) {
			return;
		}

		const currentLayout = this.getCurrentLayout(newEditingScene);

		addWidgetsToUpdateWaitingList([
			{
				defaultAccountId,
				scenarioId,
				sceneId: newEditingScene.id,
				layoutId: currentLayout?.id,
				boxId: editingBox.boxTemplateId,
				widgetId: editingWidget.id,
				body: updateBody,
			},
		]);
	};

	render() {
		const {
			selectedTab,
			activeLayout,
			breadcrumbLevel,
			showSelectWidgetTypeModal,
			showPlayer,
			editingWidget,
			editingBox,
			selectedWidgetType,
			shouldReloadPlayer,
		} = this.state;
		const { t, defaultAccountId, scenario, editingScene } = this.props;

		return (
			<ScenarioScreenContainer
				t={t}
				onExitButtonClick={this.onExitButtonClick}
				onActiveLayoutChange={(layout) => this.setState({ activeLayout: layout })}
				classNames={baseClassName}
				onPreviewButtonClick={this.onPreviewButtonClick}
				ref={this.scenarioContainerRef}
			>
				<SceneEditorSidebar
					selectedTab={selectedTab}
					onTabItemClick={this.onTabItemClick}
					baseClassName={baseClassName}
					isOnModal={false}
					breadcrumbLevel={breadcrumbLevel}
					editingScene={editingScene}
					editingWidget={editingWidget}
					activeLayout={activeLayout}
					editingBox={editingBox}
					scenarioContainerRef={this.scenarioContainerRef}
					addHoverImageRef={this.addHoverImageRef}
					selectedWidgetType={selectedWidgetType}
					widgetOverlayRef={this.widgetOverlayRef}
					widgetSettingRef={this.widgetSettingRef}
					onSelectWidgetType={this.onSelectWidgetType}
					setBreadcrumbLevel={this.setBreadcrumbLevel}
					onUploadMediaClick={this.onUploadMediaClick}
					onAddWidgetClick={this.onAddWidgetClick}
					onWidgetClick={this.onWidgetClick}
					duplicateWidget={this.duplicateWidget}
					removeWidgetInBox={this.removeWidgetInBox}
					movePositionHandler={this.movePositionHandler}
					handleDragStart={this.handleDragStart}
					onShowIconLibraryPickerModal={this.onShowIconLibraryPickerModal}
					handleReturnedMedia={this.addMediaToWidget}
				/>
				<div
					className={`${baseClassName}__main ${baseClassName}__main--${activeLayout}`}
					ref={(r) => (this.mainContainer.current = r)}
				>
					<div
						className={`${baseClassName}__player-container`}
						onClick={() => {
							if (editingWidget) {
								this.setState({
									editingWidget: null,
									selectedTab: 'edit',
									breadcrumbLevel: 'alllayers',
								});
							}
						}}
					>
						<div
							className={`${baseClassName}__player-editor`}
							style={{ height: this.state.playerAreaHeight, width: this.state.playerAreaWidth }}
						>
							{showPlayer && (
								<>
									<ScenarioEditorPlayerIframe
										key={shouldReloadPlayer}
										accountId={defaultAccountId}
										scenarioId={scenario?.id}
										pause={(handler) => (this.pauseHandler = handler)}
										play={(handler) => (this.playHandler = handler)}
										forward={(handler) => (this.forwardHandler = handler)}
										reward={(handler) => (this.rewardHandler = handler)}
										mute={(handler) => (this.muteHandler = handler)}
										unMute={(handler) => (this.unMuteHandler = handler)}
										movePlayerToPosition={(handler) => (this.movePositionHandler = handler)}
									/>
									<WidgetOverlay
										className={`${baseClassName}__widgets-overlay`}
										ref={this.widgetOverlayRef}
										onDropWidgetOnVideoEditor={this.onDropWidgetOnVideoEditor}
										activeLayout={activeLayout}
										editingScene={editingScene}
										editingWidget={editingWidget}
										editingBox={editingBox}
										onWidgetClick={this.onTimelineItemClick}
										pausePlayer={this.pauseHandler}
										removeWidgetInBox={this.removeWidgetInBox}
										scenarioContainerRef={this.scenarioContainerRef}
										timelineEditorRef={this.timelineEditorRef}
										widgetSettingRef={this.widgetSettingRef}
									/>
								</>
							)}
						</div>
					</div>
					<div
						className={`${baseClassName}__timeline-container`}
						ref={(r) => (this.timelineContainerRef.current = r)}
					>
						{showPlayer && (
							<EditorTimeline
								onMount={this.getPlayerAreaHeight}
								ref={this.timelineEditorRef}
								onDropItem={this.onDropWidgetOnTimeline}
								activeLayout={activeLayout}
								getSceneDuration={(handler) => (this.getSceneDuration = handler)}
								onItemClick={this.onTimelineItemClick}
								onPause={this.pauseHandler}
								onPlay={this.playHandler}
								onForward={this.forwardHandler}
								onReward={this.rewardHandler}
								onMute={this.muteHandler}
								onUnMute={this.unMuteHandler}
								movePlayerToPosition={this.movePositionHandler}
								accountId={defaultAccountId}
								sceneEditor={this}
								editingWidget={editingWidget}
							/>
						)}
					</div>
				</div>

				<AddWidgetModal
					open={showSelectWidgetTypeModal}
					onClose={() => this.setState({ showSelectWidgetTypeModal: false })}
					onAccept={this.onAddWidgetPromptAccept}
					title={t('SCENARIO_SCENE_EDITOR_ADD_WIDGET_MODAL_TITLE')}
					actionText={t('SCENARIO_SCENE_EDITOR_ADD_WIDGET_MODAL_OK')}
					actionAlign="center"
					baseClassName={baseClassName}
					selectedWidgetType={selectedWidgetType}
					onSelectWidgetType={this.onSelectWidgetType}
				/>

				<InteractMediaLibraryModal
					mediaType={selectedWidgetType ?? 'video'}
					ref={this.interactMediaLibraryModalRef}
					handleReturnedMedia={this.addMediaToWidget}
				/>

				<IconLibrary
					isShow={this.state.showIconLibraryModal}
					onClose={() => {
						this.setState({ showIconLibraryModal: false });
					}}
					addSelectedIconToIconWidget={this.addSelectedIconToIconWidget}
				/>
			</ScenarioScreenContainer>
		);
	}
}

const mapStateToProps = ({ session, scenarioReducer, publish }) => ({
	defaultAccountId: session.defaultAccountId,
	scenes: scenarioReducer.scenes,
	scenario: scenarioReducer.scenario,
	editingScene: scenarioReducer.editingScene,
	activeLayout: scenarioReducer.activeLayout,
	editingSceneDuration: scenarioReducer.editingSceneDuration,
	uploadingMedia: publish.uploadingMedia,
	uploadedMediaId: scenarioReducer.uploadedMediaId,
	sceneLoading: scenarioReducer.sceneLoading,
	updatedWidgets: scenarioReducer.updatedWidgets,
	updatedScenes: scenarioReducer.updatedScenes,
	newWidgets: scenarioReducer.newWidgets,
	updateWidgetWaitingDictionary: scenarioReducer.updateWidgetWaitingDictionary,
	sceneMediaIdsToRefresh: scenarioReducer.sceneMediaIdsToRefresh,
});

const mapDispatchToProps = (dispatch) => ({
	showMessage: (message, type) => dispatch(showMessage(message, type)),
	loadMediasForPagination: (accountId, mediaSet, offset, mediaName, sortType) =>
		dispatch(loadMediasForPagination(accountId, mediaSet, offset, mediaName, sortType)),
	updateScenesAction: (scenes) => dispatch(updateScenesAction(scenes)),
	updateScenarioAction: (scenario) => dispatch(updateScenarioAction(scenario)),
	updateEditingSceneAndSendToPlayer: (editingScene) => dispatch(updateEditingSceneAndSendToPlayer(editingScene)),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	getScenario: (accountId, scenarioId) => dispatch(getScenario(accountId, scenarioId)),
	setSceneLoading: (loading) => dispatch(setSceneLoading(loading)),
	updateScenarioActiveLayoutAction: (activeLayout) => dispatch(updateScenarioActiveLayoutAction(activeLayout)),
	updateIsTimeLineChangedAction: (isChanged) => dispatch(updateIsTimeLineChangedAction(isChanged)),
	setOriginScenarioAction: (scenario) => dispatch(setOriginScenarioAction(scenario)),
	loadPlayerConfiguration: (accountId) => dispatch(loadPlayerConfiguration(accountId)),
	setSaveScenario: (value) => dispatch(setSaveScenario(value)),
	updateEditingSceneAction: (editingScene) => dispatch(updateEditingSceneAction(editingScene)),
	createWidget: (accountId, scenarioId, sceneId, layoutId, boxId, widgetId, body) =>
		dispatch(createWidget(accountId, scenarioId, sceneId, layoutId, boxId, widgetId, body)),
	deleteWidget: (accountId, scenarioId, sceneId, layoutId, boxId, widgetId) =>
		dispatch(deleteWidget(accountId, scenarioId, sceneId, layoutId, boxId, widgetId)),
	updateWidget: (accountId, scenarioId, sceneId, layoutId, boxId, widgetId, body) =>
		dispatch(updateWidget(accountId, scenarioId, sceneId, layoutId, boxId, widgetId, body)),
	emptyWidgetsToUpdateWaitingList: () => dispatch(emptyWidgetsToUpdateWaitingList()),
	addWidgetsToUpdateWaitingList: (widgets) => dispatch(addWidgetsToUpdateWaitingList(widgets)),
	setRefreshSceneMedia: (newIDs) => setRefreshSceneMedia(newIDs),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ScenarioEditor);
